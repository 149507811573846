import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot,NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {
  localUserIds: any;
  localUserIdsbag: any;
  constructor(private router: Router,
    private http: HttpClient
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {



    if (localStorage.getItem('isLoggedin')) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }


  

}