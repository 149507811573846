import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'TKT issued Missing',
    icon: 'home',
    link: '/back-end/accounts_manage'
  },
  {
    label: 'Pending Task',
    icon: 'thumbs-up',
    link: '/incoming/list-incoming',
 
  }, 

  {
    label: 'Portugal Accounts',
    icon: 'check-square',
    link: '/back-end/my-list',
  },

  {
    label: 'Portugal Agents',
    icon: 'thumbs-up',
    link: '/back-end/accounts_agents',
 
  }, 
  {
    label: 'Portugal Invoice',
    icon: 'check-square',
    link: '/back-end/invoice-list',
  },

  

  {
    label: 'France Accounts',
    icon: 'check-square',
    link: '/back-end/accounts_fr',
  },
  {
    label: 'France Agents Accounts',
    icon: 'thumbs-up',
    link: '/back-end/accounts_agents_fr',
 
  }, 
  {
    label: 'France Invoice',
    icon: 'check-square',
    link: '/back-end/invoices_fr',
  },

  {
    label: 'Belgium Accounts',
    icon: 'check-square',
    link: '/back-end/accounts_be',
  },
  {
    label: 'Belgium  Agents Accounts',
    icon: 'thumbs-up',
    link: '/back-end/accounts_agents_be',
 
  }, 
 
  {
    label: 'Belgium Invoice',
    icon: 'check-square',
    link: '/back-end/invoices_be',
  },

 
  {
    label: 'News Management',
    icon: 'home',
    link: '//back-end/news-manage'
  },
  {
    label: 'Accounts Management',
    icon: 'check-square',
    link: '/back-end/accounts_manage',
  },
  {
    label: 'Agent Booking',
    icon: 'layout',
    link: '/web-sale/list-web-sale',
 
  },
  {
    label: 'Pending',
    icon: 'thumbs-down',
    link: '/pending/list-pending',
 
  }, 
 
  
  {
    label: 'Credit Card',
    icon: 'cast',
    link: '/card-payment',
 
  },
  
  {
    label: 'MultiBanco',
    icon: 'trello',
    link: '/create-mb',
 
  },
  {
    label: 'Client Management',
    icon: 'users',
    link: '/client-management',
 
  }, 
  {
    label: 'Reminder',
    icon: 'users',
    link: '/reminder',
 
  },
  {
    label: 'Error Logs',
    icon: 'alert-octagon',
    link: '/error-log',
 
  },
 
 
  


];