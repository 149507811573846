import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  rItem = {};
  authenticated = true;
  private userSubject = new Subject<any>();
  constructor(
    private _route: Router,
    private _http: HttpClient,) {
  }


  signout() {
    this.authenticated = false;
    localStorage.removeItem('backend-flight-user');
    localStorage.removeItem('backend-flight-user');
    this.removeSession();

  }
  login(requestData: any) {
    this.authenticated = true;
    console.log('login service -> login method', requestData);
    let url = environment.apiUrl + 'api_agent/agent/Api_agent_login';
    console.log('login servoce url', url);
    return this._http.post(url, requestData);

  }

  getSession() {
    return this.userSubject.asObservable();
  }
  removeSession() {
    localStorage.removeItem('backend-flight-user');
    this.userSubject.next(null);
    this._route.navigate(['auth/login']);
  }

  createSession(user: any) {
    console.log("user", user);
    localStorage.setItem('backend-flight-user', JSON.stringify(user));
    this.userSubject.next(user);
  }
  createSessionClient(user: any) {
    console.log("user", user);
    localStorage.setItem('backend-flight-user', JSON.stringify(user));
    this.userSubject.next(user);
  }
  getAccessToken() {
    let a: any = localStorage?.getItem('backend-flight-user')
    let result = JSON.parse(a).token;
    console.log('result service token==>>', result);
    return result
  }
  getUserID() {
    let a: any = localStorage?.getItem('backend-flight-user')
    let result = JSON.parse(a).detail.mail;

    return result
  }
  getUserInfo() {
    let a: any = localStorage?.getItem('backend-flight-user')
    let result = JSON.parse(a).detail;
    if (result) {
      return result
    } else {
      this.signout();
    }
  }

  getToken() {
    let a: any = localStorage?.getItem('backend-flight-user')

    let result = ""
    // = JSON.parse(a).detail.token;
    console.log("result", result)
    return result
  }
  public api_forgetpassword(data: any) {
    return this._http.post('https://www.kelvintravel.com/api_agent/flight/api_forgetpassword', data);
  }
  public api_ipaddress() {
    return this._http.post('https://www.kelvintravel.com/api_agent/agent/Api_otp/key', '');
  }
  public Api_otp(data: any) {
    return this._http.post('https://www.kelvintravel.com/api_agent/agent/Api_otp', data);
  }
}


