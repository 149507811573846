import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'payment-by-link/:clientId/:pnr/:price/:key', loadChildren: () => import('./views/pages/payment-link/payment-link.module').then(m => m.PaymentLinkModule) },
  { path: 'mbway-payment/:clientId/:pnr/:price/:key/:phone_no', loadChildren: () => import('./views/pages/mbway-payment/mbway-payment.module').then(m => m.MbwayPaymentModule) },

  
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'back-end',
        loadChildren: () => import('./views/pages/backoffice_noble/back-end/back-end.module').then(m => m.BackEndModule)
      },
      {
        path: 'filter-client',
        loadChildren: () => import('./views/pages/backoffice_noble/filter-client/filter-client.module').then(m => m.FilterClientModule)
      },
      
      {
        path: 'web-sale',
        loadChildren: () => import('./views/pages/backoffice_noble/web-sale/web-sale.module').then(m => m.WebSaleModule)
      },
      {
        path: 'bank-entry',
        loadChildren: () => import('./views/pages/backoffice_noble/bank-entry/bank-entry.module').then(m => m.BankEntryModule)
      },
      {
        path: 'bank-list',
        loadChildren: () => import('./views/pages/backoffice_noble/bank-list/bank-list.module').then(m => m.BankListModule)
      },
      {
        path: 'blank-entry',
        loadChildren: () => import('./views/pages/backoffice_noble/blank-entry/blank-entry.module').then(m => m.BlankEntryModule)
      },
      {
        path: 'card-payment',
        loadChildren: () => import('./views/pages/backoffice_noble/card-payment/card-payment.module').then(m => m.CardPaymentModule)
      },
      {
        path: 'cash-bank-out',
        loadChildren: () => import('./views/pages/backoffice_noble/cash-bank-out/cash-bank-out.module').then(m => m.CashBankOutModule)
      },
      {
        path: 'cash-entry',
        loadChildren: () => import('./views/pages/backoffice_noble/cash-entry/cash-entry.module').then(m => m.CashEntryModule)
      },
      {
        path: 'cash-list',
        loadChildren: () => import('./views/pages/backoffice_noble/cash-list/cash-list.module').then(m => m.CashListModule)
      },
      {
        path: 'create-mb',
        loadChildren: () => import('./views/pages/backoffice_noble/create-mb/create-mb.module').then(m => m.CreateMBModule)
      },
      {
        path: 'customer',
        loadChildren: () => import('./views/pages/backoffice_noble/edit-customer/edit-customer.module').then(m => m.EditCustomerModule)
      },
      {
        path: 'find-ticket',
        loadChildren: () => import('./views/pages/backoffice_noble/find-tkt/find-tkt.module').then(m => m.FindTktModule)
      },
      {
        path: 'pending',
        loadChildren: () => import('./views/pages/backoffice_noble/pending/pending.module').then(m => m.PendingModule)
      }, {
        path: 'incoming',
        loadChildren: () => import('./views/pages/backoffice_noble/incoming/incoming.module').then(m => m.IncomingModule)
      },
      {
        path: 'settlement',
        loadChildren: () => import('./views/pages/backoffice_noble/settelment/settelment.module').then(m => m.SettelmentModule)
      },
      {
        path: 'client-management',
        loadChildren: () => import('./views/pages/backoffice_noble/client-manegement/client-manegement.module').then(m => m.ClientManegementModule)
      },
       {
        path: 'reminder',
        loadChildren: () => import('./views/pages/backoffice_noble/reminder/reminder.module').then(m => m.ReminderModule)
      },
      {
        path: 'error-log',
        loadChildren: () => import('./views/pages/backoffice_noble/error-log/error-log.module').then(m => m.ErrorLogModule)
      },

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
