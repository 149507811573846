import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'backoffice';
  localUserIdsbag: any;
  localUserIds: any;
  router: any;
  
  constructor(
    private http: HttpClient,
    private auth: AuthService,
 ){}
  ngOnInit(): void {
    
    var localUserId: any;
    localUserId = JSON.parse(localStorage?.getItem('backend-flight-user') || '{}')
    this.localUserIds = localUserId?.detail.id    
    this.localUserIdsbag = localUserId?.detail.token
    const payload = { key:this.localUserIdsbag,id:this.localUserIds};
    this.http.post('https://www.kelvintravel.com/api_agent/agent/Api_login/token', payload).subscribe((response: any) => {
    
    if (response.status == '102') { 
      alert("Please Login Again")
      this.auth.signout()
      this.router.navigate(['/auth/']);
    }

  })
}
}
